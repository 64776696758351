import React from "react";
import {
  Drawer,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemPrefix,
} from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  DevicePhoneMobileIcon,
  ShoppingBagIcon,
  InboxIcon,
  HomeIcon
} from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";
import { Link as LinkR } from "react-router-dom";

const Sidebar = ({ isOpen, toggle }) => {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const openDrawer = () => {
    setOpen(true);
    toggle(true);
  };

  const closeDrawer = () => {
    setOpen(false);
    toggle(false);
  };

  // translate
  const { t } = useTranslation("global");

  return (
    <div>
      <Drawer open={open} onClose={closeDrawer}>
        <div className="mb-2 flex items-center justify-between p-4">
          <Typography variant="h5" color="blue-gray">
            {t("Navbar.Menu")}
          </Typography>
          <IconButton variant="text" color="blue-gray" onClick={closeDrawer}>
            <XMarkIcon strokeWidth={2} className="h-5 w-5" />
          </IconButton>
        </div>
        <List>
          <LinkR to="/" onClick={closeDrawer}>
            <ListItem>
              <ListItemPrefix>
                <HomeIcon className="h-5 w-5" />
              </ListItemPrefix>
              {t("Navbar.Home")}
            </ListItem>
          </LinkR>
          <LinkR to="/services" onClick={closeDrawer}>
            <ListItem>
              <ListItemPrefix>
                <ShoppingBagIcon className="h-5 w-5" />
              </ListItemPrefix>
              {t("Navbar.Serivces")}
            </ListItem>
          </LinkR>
          <LinkR to="/contactUs" onClick={closeDrawer}>
            <ListItem>
              <ListItemPrefix>
                <DevicePhoneMobileIcon className="h-5 w-5" />
              </ListItemPrefix>
              {t("Navbar.Contact")}
            </ListItem>
          </LinkR>
        </List>
      </Drawer>
    </div>
  );
};

export default Sidebar;
