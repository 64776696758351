import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavMenu,
  MobileIcon,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
  InsideLogo,
  MobileIconContainer,
  OtherComponent,
  NavLinksR,
  //NavDivider
} from "./NavbarElements";
//Translation Objects
import { useTranslation } from "react-i18next";
import TranslateButton from "../TranslateButton";
import flag_es from "../TranslateButton/assets/col.svg";
import flag_en from "../TranslateButton/assets/estados-unidos.png";
import { ButtonSlider } from "../ButtonSlider/ButtonSlider";

const Navbar = ({ toggle }) => {
  // change nav color when scroll
  const [color, setColor] = useState(false);
  // change logo
  const [scrollIcon, setScrollIcon] = useState(false);
  //translate
  const [t, i18n] = useTranslation("global");
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition > 0) {
      setScrollIcon(true);
      setColor(true);
    } else {
      setScrollIcon(false);
      setColor(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // scroll smoth to top
  const tooggleHome = () => {
    scroll.scrollToTop();
  };
  const handleClick = () => {
    // Desplazar la página hacia arriba después de 0.5 segundos
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  };

  //Flag
  const [activeFlag, setActiveFlag] = useState(flag_en); // Estado compartido

  const handleTranslateButtonClick = () => {
    setActiveFlag((prevFlag) => (prevFlag === flag_en ? flag_es : flag_en)); // Cambiar el estado compartido
    let change = activeFlag === flag_es ? "en" : "es";
    i18n.changeLanguage(change);
  };

  const renderNavMenuContainer = () => {
    return (
      <NavbarContainer>
        <NavLogo to="/" onClick={tooggleHome}>
          <InsideLogo scroll={scrollIcon} />
        </NavLogo>
        <MobileIconContainer>
          <OtherComponent>
            <TranslateButton
              onClick={handleTranslateButtonClick}
              activeFlag={activeFlag}
            />
          </OtherComponent>
          <MobileIcon onClick={toggle} color={color}>
            <FaBars />
          </MobileIcon>
        </MobileIconContainer>
        <NavMenu>
          <NavItem>
            <NavLinks
              to="/"
              color={color}
              onClick={tooggleHome}
              className="transition hover:bg-blue-500 hover:text-white"
            >
              {t("Navbar.Home")}
            </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinksR>
              <ButtonSlider />
            </NavLinksR>  
          </NavItem>
          <NavItem>
            <NavLinks
              to="/aboutus"
              color={color}
              onClick={tooggleHome}
              className="transition hover:bg-blue-500 hover:text-white"
            >
              {t("Navbar.AboutUs")}
            </NavLinks>
          </NavItem>
        </NavMenu>

        <NavBtn>
          <TranslateButton
            onClick={handleTranslateButtonClick}
            activeFlag={activeFlag}
          />
          <NavBtnLink to="/contactUs" onClick={handleClick}>
            {t("Navbar.Contact")}
          </NavBtnLink>
        </NavBtn>
      </NavbarContainer>
    );
  };
  return (
    <>
      <Nav color={color}>{renderNavMenuContainer()}</Nav>
    </>
  );
};

export default Navbar;
