import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaWhatsapp,
  FaLinkedin,
} from "react-icons/fa";
import {
  FooterContainer,
  FooterSubscription,
  FooterSubHeading,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  SocialIcon,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
} from "./FooterElements";
import { useTranslation } from "react-i18next";
function Footer() {
  const text =
    "https://wa.me/573202718442/?text=Hola%estoy%20interesado,%20me%20podrias%20brindar%20mas%20informacion?";
  const [t, i18n] = useTranslation("global");

  return (
    <div>
      <FooterContainer>
        <FooterSubscription>
          <FooterSubHeading>
            {t("Footer.Heading")}
          </FooterSubHeading>
          {/*<FooterSubText>Puedes subscribirte cuando quieras.</FooterSubText>
                <Form>
                    <FormInput name='email' type='email' placeholder='Email' />
                    <Button fontBig>Subscribe</Button>
                </Form>*/}
        </FooterSubscription>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>{t("Footer.Info")}</FooterLinkTitle>
              <FooterLink to="/">{t("Footer.Contact")}</FooterLink>
              <FooterLink to="/">{t("Footer.Suport")}</FooterLink>
              <FooterLink to="/">{t("Footer.Politic")}</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>{t("Footer.Red")}</FooterLinkTitle>
              <FooterLink to="/">Instagram</FooterLink>
              <FooterLink to={text}>Whatsapp</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/">
              <SocialIcon />
              Freedom
            </SocialLogo>
            <WebsiteRights>TML © 2023</WebsiteRights>
            <SocialIcons>
              <SocialIconLink href="/" target="_blank" aria-label="Facebook">
                <FaFacebook />
              </SocialIconLink>
              <SocialIconLink href="/" target="_blank" aria-label="Instagram">
                <FaInstagram />
              </SocialIconLink>
              <SocialIconLink href={text} target="_blank" aria-label="Whatsapp">
                <FaWhatsapp />
              </SocialIconLink>
              <SocialIconLink href="/" target="_blank" aria-label="LinkedIn">
                <FaLinkedin />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterContainer>
    </div>
  );
}

export default Footer;
