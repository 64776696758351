import React from "react";
import { IconButton } from "@material-tailwind/react";

const TranslateButton = ({ activeFlag, onClick }) => {
  const handleButtonClick = () => {
    onClick(); // Llamar a la función onClick proporcionada por el componente padre
  };

  return (
    <>
      <IconButton onClick={handleButtonClick} className="mr-4">
        <img src={activeFlag} alt="Bandera" className="w-6 h-6" />
      </IconButton>
    </>
  );
};

export default TranslateButton;

