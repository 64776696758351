import imgRefractiva1 from "./ServicesImgs/Refractaria-transformed.jpeg";
import imgRefractiva2 from "./ServicesImgs/Refractaria2-transformed.jpeg";
import imgCatarata1 from "./ServicesImgs/cataratafondo-transformed.jpeg";
import imgCatarata2 from "./ServicesImgs/catarataimg-transformed.jpeg";
import imgRodilla1 from "./ServicesImgs/rodillafondo-transformed.jpeg";
import imgRodilla2 from "./ServicesImgs/rodillaimg.jpg";
import fondoCirugia from "./ServicesImgs/cirugia-fondo-azulemio-transformed.jpeg";
import imgCadera1 from "../../components/Carts/ServicesImgs/Cadera.jpg";
import caderaLoca from "./ServicesImgs/caderaloca-transformed.jpeg";
import imgbariatrica from "./ServicesImgs/bariatrica-transformed.jpeg";
import lipo from "./ServicesImgs/lipos.jpg";
import plasticaSenos from "./ServicesImgs/plasticapechos.jpg";
import fondoSenos from "../../components/Carts/ServicesImgs/explantacion-levantamiento.jpg";
import abdominoplastia from "./ServicesImgs/abdominoplastia.jpg";
import rino from "./ServicesImgs/rinoplastia.webp";
import columna from "./ServicesImgs/fondo-columna.jpg";
import columnaimg from "../../components/Carts/ServicesImgs/columna-transformed.jpeg";
import fondoOdonto from "./ServicesImgs/fondoOdontologia.jpeg";
import implantes from "./ServicesImgs/implantes.jpeg";
import diseno from "./ServicesImgs/Imagen2.png";
import ortoInv from "./ServicesImgs/OrtoInv-2.jpeg";
import fondoOdontoInv from "./ServicesImgs/Fondo-Ort.jpg";
const dataServices = [
  {
    id: 1,
    path: "/Cirugía-Refractiva",
    Hero: {
      imagen: imgRefractiva1,
      titulo: "Cirugía Refractiva",
      descripcion:
        "Transforma tu visión y disfruta de una vida sin límites. Nuestra especialidad en corrección de errores refractivos, como miopía, hipermetropía, astigmatismo y presbicia, te brinda la oportunidad de tener una visión clara y nítida.",
    },
    Body: {
      imagen: imgRefractiva2,
      tituloSmall: "Oftalmológicos",
      tituloDefinicion: "Definición",
      descripcionDefinicion:
        "La cirugía refractiva es un procedimiento quirúrgico utilizado para corregir problemas de visión comunes, como la miopía (visión corta), la hipermetropía (visión larga), el astigmatismo y, en algunos casos, la presbicia (vista cansada).\n\nEl objetivo principal de esta cirugía es mejorar la agudeza visual sin la necesidad de usar gafas o lentes de contacto.",
      tituloCirugia: "Cirugia",
      descripcionCirugia:
        "La cirugía refractiva se realiza con el objetivo de corregir errores de refracción que afectan la visión de las personas.Estos errores pueden incluir la miopía, hipermetropía, astigmatismo y presbicia.Cuando existe un error de refracción, el ojo no logra enfocar la luz correctamente sobre la retina, lo que resulta en una visión borrosa o distorsionada.\n\nLa forma irregular de la córnea, la curvatura del cristalino o la longitud axial del ojo pueden ser las causas de estos errores refractivos.La cirugía refractiva busca corregir estas irregularidades y mejorar la calidad de visión de las personas.La principal motivación para someterse a una cirugía refractiva es poder disfrutar de una visión más clara y nítida, sin depender de gafas o lentes de contacto.\n\nAl corregir los errores de refracción, la cirugía refractiva permite a las personas ver con mayor nitidez y realizar actividades cotidianas como leer, conducir, trabajar frente a una computadora y participar en actividades deportivas sin dificultad visual.",
    },
  },
  {
    id: 2,
    path: "/Cirugía-Catarata",
    Hero: {
      imagen: imgRefractiva1,
      titulo: "Cirugía Catarata",
      descripcion:
        "Recupera la claridad de tu visión y mejora tu calidad de vida. Nuestra especialidad en cirugía de catarata te brinda la oportunidad de eliminar la opacidad del cristalino y restaurar tu visión de forma segura y eficaz.",
    },
    Body: {
      imagen: imgCatarata2,
      tituloSmall: "Oftalmológicos",
      tituloDefinicion: "Definición",
      descripcionDefinicion:
        "La cirugía de catarata es un procedimiento quirúrgico utilizado para extraer el cristalino opaco y reemplazarlo con un lente intraocular claro. La catarata es una condición en la que el cristalino se vuelve opaco, lo que causa visión borrosa o nublada.\n\nEl objetivo principal de esta cirugía es restaurar la claridad de la visión y mejorar la calidad de vida del paciente. Durante el procedimiento, se realiza una pequeña incisión en el ojo y se utiliza tecnología avanzada para descomponer y aspirar la catarata, permitiendo la inserción de un lente artificial que restablece la visión clara.",
      tituloCirugia: "Cirugía",
      descripcionCirugia:
        "La cirugía de catarata se realiza con el objetivo de tratar la opacidad del cristalino y mejorar la visión del paciente. Durante la intervención, se realiza una incisión mínima en el ojo y se utiliza un láser o una técnica de ultrasonido para fragmentar y extraer la catarata.\n\nUna vez eliminada la catarata, se coloca un lente intraocular artificial en el ojo para restaurar la visión. Este lente puede ser monofocal, corrigiendo la visión a una distancia específica, o multifocal, permitiendo una visión clara a diferentes distancias.\n\nLa cirugía de catarata es un procedimiento seguro y eficaz que brinda resultados significativos en la mejora de la calidad de visión. Después de la cirugía, muchos pacientes experimentan una recuperación rápida y una visión más clara, lo que les permite realizar sus actividades diarias sin dificultad.",
    },
  },
  {
    id: 3,
    path: "/Reemplazo-Rodilla",
    Hero: {
      imagen: imgRodilla2,
      titulo: "Cirugía de Reemplazo de Rodilla",
      descripcion:
        "Recupera la movilidad y disminuye el dolor en tus rodillas. Nuestra especialidad en cirugía de reemplazo de rodilla te brinda la oportunidad de restaurar la función de tus articulaciones y mejorar tu calidad de vida.",
    },
    Body: {
      imagen: imgRodilla1,
      tituloSmall: "Ortopédicos",
      tituloDefinicion: "Definición",
      descripcionDefinicion:
        "La cirugía de reemplazo de rodilla es un procedimiento quirúrgico en el que se retira la articulación de la rodilla dañada y se reemplaza con una prótesis artificial. Esta cirugía se realiza cuando la articulación de la rodilla está severamente dañada o desgastada debido a condiciones como la artritis, lesiones o enfermedades degenerativas.\n\nEl objetivo principal de esta cirugía es aliviar el dolor, mejorar la función y restaurar la movilidad de la rodilla afectada.",
      tituloCirugia: "Cirugía",
      descripcionCirugia:
        "La cirugía de reemplazo de rodilla se lleva a cabo mediante la eliminación de la superficie dañada del fémur y la tibia, y su reemplazo por componentes protésicos que recrean la articulación de la rodilla. Estos componentes protésicos están hechos de materiales resistentes y duraderos que permiten un movimiento suave y natural.\n\nDurante el procedimiento, se realiza una incisión en la rodilla y se retiran las partes dañadas. Luego, se coloca la prótesis en su lugar, asegurándose de que se ajuste de manera adecuada y estable. Una vez completada la cirugía, se cierra la incisión y se inicia el proceso de rehabilitación para ayudar al paciente a recuperar la fuerza y el rango de movimiento en la rodilla.\n\nEl reemplazo de rodilla es un procedimiento efectivo que ha demostrado mejorar significativamente la calidad de vida de los pacientes, aliviando el dolor y permitiendo una mayor movilidad y actividad física.",
    },
  },
  {
    id: 4,
    path: "/Reemplazo-Cadera",
    Hero: {
      imagen: imgCadera1,
      titulo: "Cirugía de Reemplazo de Cadera",
      descripcion:
        "Recupera la movilidad y mejora tu calidad de vida. Nuestra especialidad en cirugía de reemplazo de cadera te brinda la oportunidad de aliviar el dolor y restaurar la función de tu cadera afectada.",
    },
    Body: {
      imagen: caderaLoca,
      tituloSmall: "Ortopédicos",
      tituloDefinicion: "Definición",
      descripcionDefinicion:
        "La cirugía de reemplazo de cadera es un procedimiento quirúrgico en el que se reemplaza la articulación de la cadera dañada por una prótesis artificial. Esta cirugía se realiza cuando la cadera está severamente dañada debido a condiciones como la artritis, lesiones o enfermedades degenerativas.\n\nEl objetivo principal de esta cirugía es aliviar el dolor, mejorar la movilidad y restaurar la función de la cadera afectada.",
      tituloCirugia: "Cirugía",
      descripcionCirugia:
        "La cirugía de reemplazo de cadera implica la remoción de la cabeza del fémur dañado y la inserción de una prótesis en su lugar. Esta prótesis está compuesta por una bola de metal que se coloca en el extremo del fémur y una copa de plástico que se coloca en la cavidad de la pelvis. Estos componentes artificiales permiten un movimiento suave y natural de la cadera.\n\nDurante la cirugía, se realiza una incisión en la cadera y se retiran las partes dañadas. Luego, se coloca la prótesis en su posición correcta, asegurándose de que esté estable y bien fijada. Después de la cirugía, se inicia un programa de rehabilitación para ayudar al paciente a recuperar la fuerza y el rango de movimiento en la cadera.\n\nEl reemplazo de cadera es un procedimiento exitoso que ha demostrado mejorar la calidad de vida de los pacientes, reduciendo el dolor y permitiendo una mayor movilidad y actividad física.",
    },
  },
  {
    id: 5,
    path: "/Cirugía-Bariátrica",
    Hero: {
      imagen: fondoCirugia,
      titulo: "Cirugía Bariátrica",
      descripcion:
        "Transforma tu vida y mejora tu salud. Nuestra especialidad en cirugía bariátrica te brinda la oportunidad de combatir la obesidad y alcanzar un peso saludable.",
    },
    Body: {
      imagen: imgbariatrica,
      tituloSmall: "Cirugía",
      tituloDefinicion: "Definición",
      descripcionDefinicion:
        "La cirugía bariátrica es un conjunto de procedimientos quirúrgicos utilizados para tratar la obesidad y sus complicaciones. Estos procedimientos incluyen la reducción del tamaño del estómago, la modificación de la anatomía del sistema digestivo o la combinación de ambos.\n\nEl objetivo principal de esta cirugía es promover la pérdida de peso sostenida a largo plazo y mejorar las condiciones de salud relacionadas con la obesidad, como la diabetes tipo 2, la hipertensión arterial y la apnea del sueño.",
      tituloCirugia: "Tipos de Cirugía",
      descripcionCirugia:
        "Existen diferentes tipos de cirugía bariátrica, entre los cuales se incluyen:\n\n1. Bypass gástrico: en este procedimiento, se crea una bolsa gástrica más pequeña y se desvía una parte del intestino delgado para reducir la absorción de nutrientes.\n\n2. Manga gástrica: en esta cirugía, se remueve una porción del estómago para reducir su capacidad y limitar la ingesta de alimentos.\n\n3. Banda gástrica ajustable: en este procedimiento, se coloca una banda alrededor del estómago para crear una pequeña bolsa gástrica y controlar la cantidad de alimentos que se pueden consumir.\n\nCada tipo de cirugía bariátrica tiene sus propias ventajas, consideraciones y riesgos, y la elección del procedimiento adecuado depende de varios factores, incluyendo la evaluación médica y las necesidades individuales del paciente.",
    },
  },
  {
    id: 6,
    path: "/Liposucción",
    Hero: {
      imagen: fondoCirugia,
      titulo: "Liposucción",
      descripcion:
        "Elimina el exceso de grasa y moldea tu figura. Nuestra especialidad en liposucción te ofrece la posibilidad de deshacerte de los depósitos de grasa no deseados, logrando una silueta más estilizada y definida.",
    },
    Body: {
      imagen: lipo,
      tituloSmall: "Cirugía",
      tituloDefinicion: "Definición",
      descripcionDefinicion:
        "La liposucción es un procedimiento quirúrgico diseñado para eliminar los depósitos de grasa localizada en diversas áreas del cuerpo. Mediante esta técnica, se extrae el exceso de grasa de manera precisa, permitiendo esculpir y mejorar la forma corporal.",
      tituloCirugia: "Proceso de Liposucción",
      descripcionCirugia:
        "El proceso de liposucción generalmente implica los siguientes pasos:\n\n1. Evaluación médica: antes de la cirugía, se realizará una evaluación médica exhaustiva para determinar las áreas a tratar y el enfoque más adecuado para obtener los resultados deseados.\n\n2. Anestesia: se administrará anestesia general o local con sedación para garantizar tu comodidad durante el procedimiento.\n\n3. Incisiones y aspiración de grasa: a través de pequeñas incisiones estratégicas, se insertarán cánulas delgadas para succionar el exceso de grasa de las áreas seleccionadas.\n\n4. Cierre de incisiones y recuperación: una vez completada la liposucción, se cerrarán las incisiones con suturas y se aplicará un vendaje o una prenda de compresión para ayudar en la cicatrización y la recuperación.\n\nLa liposucción es una opción popular para personas que desean eliminar depósitos de grasa que no responden a la dieta y el ejercicio. Es importante tener en cuenta que la liposucción no es un sustituto de un estilo de vida saludable y no es adecuada para tratar la obesidad. Consulta con un cirujano plástico especializado para obtener más información y determinar si la liposucción es adecuada para ti.",
    },
  },
  {
    id: 7,
    path: "/Cirugía-plastica-senos",
    Hero: {
      imagen: fondoSenos,
      titulo: "Cirugía Plástica y Estética de Senos",
      descripcion:
        "Transforma y mejora la apariencia de tus senos. Nuestra especialidad en cirugía plástica y estética de senos ofrece una amplia gama de procedimientos, como aumento de senos, reducción de senos, elevación de senos y reconstrucción mamaria, para ayudarte a lograr la apariencia deseada.",
    },
    Body: {
      imagen: plasticaSenos,
      tituloSmall: "Procedimientos",
      tituloDefinicion: "Definición",
      descripcionDefinicion:
        "La cirugía plástica y estética de senos engloba una variedad de procedimientos diseñados para mejorar la apariencia y la forma de los senos. Estos procedimientos pueden incluir aumento de senos con implantes, reducción de senos para aliviar el malestar o la incomodidad, elevación de senos para corregir la flacidez y la ptosis mamaria, y reconstrucción mamaria después de una mastectomía o lesión.",
      tituloCirugia: "Cirugía",
      descripcionCirugia:
        "El procedimiento de cirugía plástica y estética de senos puede variar según el tipo de procedimiento específico que se realice. Generalmente, implica los siguientes pasos:\n\n1. Evaluación médica: antes de la cirugía, se llevará a cabo una evaluación médica completa para determinar el procedimiento más adecuado según tus objetivos y condiciones médicas.\n\n2. Anestesia: se administrará anestesia general o local con sedación, dependiendo del procedimiento y las preferencias del paciente.\n\n3. Incisiones y correcciones: se realizarán incisiones estratégicas para realizar las correcciones deseadas, como la inserción de implantes, la eliminación de tejido mamario o la elevación de los senos.\n\n4. Cierre de incisiones y recuperación: una vez completada la cirugía, se cerrarán las incisiones con suturas y se aplicarán vendajes o prendas de compresión para facilitar la cicatrización y la recuperación.\n\nEs importante consultar con un cirujano plástico certificado para determinar el procedimiento más adecuado para tus necesidades y objetivos estéticos. El cirujano evaluará tus características individuales y te brindará información detallada sobre el procedimiento, los riesgos y los resultados esperados.",
    },
  },
  {
    id: 8,
    path: "/Abdominoplastia",
    Hero: {
      imagen: fondoCirugia,
      titulo: "Abdominoplastia",
      descripcion:
        "Obtén un abdomen firme y tonificado a través de la abdominoplastia. Este procedimiento quirúrgico remueve el exceso de piel y grasa abdominal, mientras que también fortalece los músculos abdominales. Disfruta de una apariencia más esbelta y definida.",
    },
    Body: {
      imagen: abdominoplastia,
      tituloSmall: "Procedimiento",
      tituloDefinicion: "Definición",
      descripcionDefinicion:
        "La abdominoplastia, también conocida como cirugía de abdomen o tummy tuck, es un procedimiento quirúrgico diseñado para remodelar y mejorar la apariencia del abdomen. Se enfoca en eliminar el exceso de piel y grasa abdominal, así como en fortalecer los músculos abdominales debilitados o separados.",
      tituloCirugia: "Procedimiento",
      descripcionCirugia:
        "El procedimiento de abdominoplastia generalmente implica los siguientes pasos:\n\n1. Anestesia: se administrará anestesia general para garantizar tu comodidad y seguridad durante el procedimiento.\n\n2. Incisiones: se realizará una incisión horizontal en el área del abdomen, generalmente justo encima del área púbica. La longitud de la incisión dependerá de la cantidad de piel y grasa que se deba eliminar.\n\n3. Remoción de exceso de piel y grasa: se eliminará el exceso de piel y grasa abdominal mediante técnicas quirúrgicas específicas. Si es necesario, también se puede realizar liposucción para mejorar los contornos.\n\n4. Reparación muscular: si los músculos abdominales están debilitados o separados, se realizará una reparación para tensar y fortalecer la pared abdominal.\n\n5. Cierre de incisiones y recuperación: se cerrarán las incisiones con suturas y se aplicarán vendajes y/o prendas de compresión para favorecer la cicatrización y la recuperación.\n\nEs importante seguir las instrucciones de cuidado postoperatorio proporcionadas por tu cirujano para lograr los mejores resultados. Recuerda que la abdominoplastia es un procedimiento quirúrgico y conlleva ciertos riesgos y tiempo de recuperación. Consulta a un cirujano plástico certificado para obtener más información sobre la abdominoplastia y determinar si eres un candidato adecuado.",
    },
  },
  {
    id: 9,
    path: "/Rinoplastia",
    Hero: {
      imagen: fondoCirugia,
      titulo: "Rinoplastia",
      descripcion:
        "Transforma la forma y apariencia de tu nariz a través de la rinoplastia. Este procedimiento quirúrgico permite corregir imperfecciones estéticas, mejorar la función respiratoria y lograr un equilibrio facial armonioso. Obtén una nariz que resalte tu belleza natural.",
    },
    Body: {
      imagen: rino,
      tituloSmall: "Procedimiento",
      tituloDefinicion: "Definición",
      descripcionDefinicion:
        "La rinoplastia es un procedimiento quirúrgico que se realiza para corregir y mejorar la forma y apariencia de la nariz. También se conoce como cirugía de nariz o cirugía nasal. El objetivo principal de la rinoplastia es lograr una nariz más equilibrada y armoniosa con los rasgos faciales.",
      tituloCirugia: "Procedimiento",
      descripcionCirugia:
        "El procedimiento de rinoplastia generalmente incluye los siguientes pasos:\n\n1. Anestesia: se administrará anestesia general o local con sedación para garantizar tu comodidad durante la cirugía.\n\n2. Incisiones: se realizarán incisiones dentro de la nariz (rinoplastia cerrada) o en la base de la nariz entre las fosas nasales (rinoplastia abierta). La elección de la técnica dependerá de las necesidades individuales de cada paciente.\n\n3. Remodelación de la estructura nasal: se realizarán ajustes en el cartílago y hueso nasal para lograr la forma deseada. Esto puede incluir la reducción de tamaño, corrección de desviaciones, elevación de la punta nasal, entre otros.\n\n4. Cierre de incisiones y recuperación: una vez finalizada la remodelación, se cerrarán las incisiones con suturas. Se aplicarán vendajes y/o una férula nasal para proteger la nariz durante la fase de recuperación.\n\nEs importante seguir las instrucciones de cuidado postoperatorio proporcionadas por tu cirujano para garantizar una recuperación adecuada y obtener los resultados deseados. Recuerda que la rinoplastia es un procedimiento quirúrgico y conlleva ciertos riesgos y tiempo de recuperación. Consulta a un cirujano plástico certificado para obtener más información sobre la rinoplastia y determinar si eres un candidato adecuado.",
    },
  },
  {
    id: 10,
    path: "/Columna-Mínimamente-invasiva",
    Hero: {
      imagen: columna,
      titulo: "Cirugía de Columna Mínimamente Invasiva",
      descripcion:
        "Recupera la salud de tu columna de manera menos invasiva con la cirugía de columna mínimamente invasiva. Este enfoque quirúrgico utiliza técnicas avanzadas que permiten tratar afecciones de la columna vertebral con incisiones más pequeñas, menor daño a los tejidos circundantes y una recuperación más rápida. Vuelve a disfrutar de una vida sin dolor y con mayor movilidad.",
    },
    Body: {
      imagen: columnaimg,
      tituloSmall: "Procedimiento",
      tituloDefinicion: "Definición",
      descripcionDefinicion:
        "La cirugía de columna mínimamente invasiva es un enfoque quirúrgico especializado utilizado para tratar una variedad de afecciones de la columna vertebral, como hernias de disco, estenosis espinal, deformidades de la columna y lesiones vertebrales. A diferencia de los procedimientos tradicionales de columna, esta técnica utiliza incisiones más pequeñas y herramientas especiales para acceder y tratar la columna vertebral con menos daño a los tejidos circundantes.",
      tituloCirugia: "Cirugía",
      descripcionCirugia:
        "El procedimiento de cirugía de columna mínimamente invasiva generalmente incluye los siguientes pasos:\n\n1. Anestesia: se administrará anestesia general o regional para garantizar tu comodidad durante la cirugía.\n\n2. Incisiones: se realizarán pequeñas incisiones cerca del área afectada de la columna vertebral. Estas incisiones suelen ser de aproximadamente 1-2 centímetros.\n\n3. Acceso a la columna: se utilizarán instrumentos especiales, como endoscopios y microscopios, para acceder a la columna vertebral a través de las incisiones pequeñas. Estos instrumentos permiten al cirujano visualizar y tratar la columna con precisión.\n\n4. Tratamiento de la afección: una vez que se accede a la columna, el cirujano utilizará técnicas específicas para tratar la afección, como la extracción de hernias de disco, la fusión vertebral o la corrección de deformidades.\n\n5. Cierre de incisiones y recuperación: una vez finalizado el tratamiento, se cerrarán las incisiones con suturas o adhesivos especiales. La recuperación de la cirugía de columna mínimamente invasiva suele ser más rápida que la de los procedimientos tradicionales de columna.\n\nEs importante seguir las instrucciones de cuidado postoperatorio proporcionadas por tu médico para garantizar una recuperación adecuada y obtener los mejores resultados. Consulta a un especialista en cirugía de columna para obtener más información sobre la cirugía de columna mínimamente invasiva y determinar si es adecuada para tu caso específico.",
    },
  },
  {
    id: 11,
    path: "/Implantes-Dentales",
    Hero: {
      imagen: fondoOdonto,
      titulo: "Implantes Dentales",
      descripcion:
        "Recupera la funcionalidad y estética de tu sonrisa con los implantes dentales. Esta solución avanzada reemplaza las raíces de los dientes perdidos, brindando una base sólida para la colocación de dientes artificiales. Disfruta de una sonrisa completa y recupera la confianza en tu masticación y habla.",
    },
    Body: {
      imagen: implantes,
      tituloSmall: "Procedimiento",
      tituloDefinicion: "Definición",
      descripcionDefinicion:
        "Los implantes dentales son una opción de tratamiento utilizada para reemplazar dientes perdidos. Consisten en pequeños tornillos de titanio que se insertan en el hueso maxilar o mandibular, actuando como raíces artificiales. Estos tornillos proporcionan una base estable para colocar dientes artificiales, como coronas o prótesis dentales, que se verán y funcionarán como dientes naturales.",
      tituloCirugia: "Cirugía",
      descripcionCirugia:
        "El procedimiento de colocación de implantes dentales generalmente incluye los siguientes pasos:\n\n1. Evaluación y planificación: se realizará una evaluación exhaustiva de tu salud dental y se planificará el tratamiento de acuerdo a tus necesidades específicas.\n\n2. Colocación del implante: se realizará una cirugía menor para insertar el implante dental en el hueso maxilar o mandibular. Después de la colocación, se dejará que el implante se integre con el hueso en un proceso llamado osteointegración.\n\n3. Colocación de la prótesis dental: una vez que el implante se haya fusionado con el hueso, se colocará la prótesis dental personalizada, como una corona o una prótesis dentobucal.\n\n4. Seguimiento y cuidado: después del procedimiento, se proporcionarán instrucciones de cuidado postoperatorio y se realizarán visitas de seguimiento para garantizar la salud y funcionalidad a largo plazo de los implantes dentales.",
    },
  },
  {
    id: 12,
    path: "/Diseño-Sonrisa",
    Hero: {
      imagen: fondoOdonto,
      titulo: "Diseño de Sonrisa",
      descripcion:
        "Transforma tu sonrisa y mejora tu apariencia con el diseño de sonrisa. Este enfoque estético dental combina diversas técnicas para lograr una sonrisa armoniosa y radiante. Desde el blanqueamiento dental hasta la colocación de carillas, el diseño de sonrisa personalizado puede ayudarte a conseguir la sonrisa que siempre has deseado.",
    },
    Body: {
      imagen: diseno,
      tituloSmall: "Procedimiento",
      tituloDefinicion: "Definición",
      descripcionDefinicion:
        "El diseño de sonrisa es un proceso personalizado que busca mejorar la apariencia estética de la sonrisa. A través de una combinación de tratamientos dentales, como blanqueamiento, carillas dentales, alineación de dientes y reemplazo de restauraciones antiguas, se logra una sonrisa más atractiva y equilibrada.",
      tituloCirugia: "Tratamientos comunes",
      descripcionCirugia:
        "Los tratamientos comunes en el diseño de sonrisa pueden incluir:\n\n1. Blanqueamiento dental: para aclarar y mejorar el color de los dientes.\n\n2. Carillas dentales: capas delgadas de porcelana que se adhieren a la superficie frontal de los dientes para corregir imperfecciones y mejorar la apariencia.\n\n3. Ortodoncia: para corregir la alineación dental y lograr una sonrisa más recta.\n\n4. Implantes dentales: para reemplazar dientes perdidos y restaurar la funcionalidad y estética de la sonrisa.\n\n5. Restauraciones dentales: como coronas o puentes, para reparar dientes dañados o restaurar la estructura dental.\n\nConsulta con nostros el diseño de sonrisa para obtener más información sobre los tratamientos específicos que podrían beneficiarte y lograr la sonrisa que siempre has deseado.",
    },
  },
  {
    id: 13,
    path: "/Ortodoncia-Invisible",
    Hero: {
      imagen: fondoOdontoInv,
      titulo: "Ortodoncia Invisible",
      descripcion:
        "Transforma tu sonrisa y mejora tu apariencia con la Ortodoncia Invisible. Este tratamiento estético dental utiliza alineadores transparentes para corregir la alineación de tus dientes de manera discreta y efectiva. Olvídate de los brackets tradicionales y descubre una forma cómoda y discreta de lograr una sonrisa perfecta.",
    },
    Body: {
      imagen: ortoInv,
      tituloSmall: "Procedimiento",
      tituloDefinicion: "Definición",
      descripcionDefinicion:
        "La Ortodoncia Invisible es un procedimiento personalizado que busca mejorar la apariencia estética de la sonrisa. A través de alineadores transparentes que se ajustan a tus dientes y aplican una presión suave para moverlos gradualmente a la posición deseada, se logra una sonrisa más atractiva y equilibrada.",
      tituloCirugia: "Tratamientos Comunes",
      descripcionCirugia:
        "Los tratamientos comunes en Ortodoncia Invisible pueden incluir:\n\n1. Evaluación Inicial: El ortodoncista evaluará tu boca y tomará impresiones para crear tus alineadores personalizados.\n\n2. Uso de Alineadores: Deberás llevar los alineadores transparentes durante la mayor parte del día, quitándolos solo para comer y cepillarte los dientes.\n\n3. Seguimiento: Programarás visitas de seguimiento para recibir nuevos juegos de alineadores a medida que tus dientes se muevan.\n\n4. Resultados: Con el tiempo, verás cómo tus dientes se alinean gradualmente hasta lograr una sonrisa perfecta.",
    },
  },
];

export default dataServices;
