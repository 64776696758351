import React from "react";
import { Link } from "react-router-dom";

import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
  Card,
} from "@material-tailwind/react";
import { ChevronDownIcon, GlobeAmericasIcon} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import dataQuirurgicos from "../Carts/dataServices";
import dataNoQuirurgicos from "../Carts/dataServices2";
import galenix from "../../Images/Wellnes.png";
export function ButtonSlider() {
  const [openMenu, setOpenMenu] = React.useState(false);
  const [t] = useTranslation("global");

  const datosQuirurngicosTranslate = dataQuirurgicos.map((data) => {
    const translation = t("Services.info").find((d) => d.id === data.id);
    return {
      ...data,
      titulo: t(translation.titulo),
      descripcion: t(translation.descripcion),
    };
  });

  const datosNoQuirurngicosTranslate = dataNoQuirurgicos.map((data) => {
    const translation = t("Services.info2").find((d) => d.id === data.id);
    return {
      ...data,
      titulo: t(translation.titulo),
      descripcion: t(translation.descripcion),
    };
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Esta opción hace que el desplazamiento sea suave
    });
  };

  console.log(datosNoQuirurngicosTranslate);

  return (
    <div className="flex h-full w-full text-center hover:bg-blue-700">
      <Menu open={openMenu} handler={setOpenMenu} allowHover>
        <MenuHandler>
          <Button
            variant="text"
            className="flex items-center gap-3 text-base font-normal capitalize tracking-normal"
          >
            <Link
              to="services"
              className="flex items-center gap-2 text-white"
              onClick={scrollToTop}
            >
              {t("Navbar.Serivces")}{" "}
            </Link>
            
            <ChevronDownIcon
              strokeWidth={2.5}
              className={`h-3.5 w-3.5 transition-transform ${
                openMenu ? "rotate-180" : ""
              }`}
            />
          </Button>
        </MenuHandler>
        <MenuList className="hidden w-[36rem] grid-cols-7 gap-3 overflow-visible lg:grid">
          <Card
            color="blue"
            shadow={false}
            variant="gradient"
            className="col-span-3 grid h-full w-full place-items-center rounded-md"
          >
            <img src={galenix} className="h-32 w-32"/>
          </Card>
          <ul className="col-span-4 flex w-full flex-col gap-1">
            <MenuItem className="flex w-full h-full">
              <Menu>
                <MenuHandler>
                  <Button className="bg-transparent text-black flex w-full h-full shadow-none no-hover-shadow">
                    Galenix ecosystem
                  </Button>
                </MenuHandler>
                <MenuList className="max-h-72">
                  {datosQuirurngicosTranslate.map((data) => (
                    <MenuItem key={data.id}>
                      <Link
                        to={data.enlace}
                        className="flex items-center gap-2"
                        onClick={scrollToTop}
                      >
                        {data.titulo}
                      </Link>
                    </MenuItem>
                  ))}
                  <hr className="my-3" />
                  {datosNoQuirurngicosTranslate.map((data) => (
                    <MenuItem key={data.id}>
                      <Link
                        to={data.enlace}
                        className="flex items-center gap-2"
                        onClick={scrollToTop}
                      >
                        {data.titulo}
                      </Link>
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </MenuItem>

            <MenuItem className="flex w-full h-full">
              <Button
                to="/consult"
                className="bg-transparent text-black flex w-full h-full shadow-none no-hover-shadow"
              >
                <Link to="/consult" className="flex items-center gap-2">
                  Integral Health Consulting
                </Link>
              </Button>
            </MenuItem>
          </ul>
        </MenuList>
      </Menu>
    </div>
  );
}
