import fondoCirugia from "./ServicesImgs/cirugia-fondo-azulemio-transformed.jpeg";
import imgExplantacion from "./ServicesImgs/explantacionMama-transformed.jpeg";
import imgImplantacion from "./ServicesImgs/implantacion-transformed.jpeg";
const dataServicesA = [
  {
    id: 1,
    path:"/Mamoplastia-aumento-reducción",
    Hero: {
      imagen: fondoCirugia,
      titulo: "Mamoplastia de aumento o reducción",
      descripcion:
        "También conocida como cirugía de aumento, reducción o levantamiento de senos, la mamoplastia ofrece opciones personalizadas para abordar diferentes preocupaciones estéticas y de salud relacionadas con los senos.",
    },
    Bodys: [
      {
        id: 1,
        titulo: "Explantación mamaria o retiro de implantes",
        imagen: imgExplantacion,
        tituloSmall: "Cirugía",
        tituloDefinicion: "Definición",
        descripcionDefinicion:
          "La explantación mamaria es un procedimiento quirúrgico utilizado para remover los implantes mamarios que han sido previamente colocados. Este procedimiento permite a las mujeres que desean eliminar sus implantes lograr una apariencia natural de los senos y restaurar su confianza y bienestar.",
        tituloCirugia: "Proceso de Explantación",
        descripcionCirugia:
          "El proceso de explantación mamaria generalmente implica los siguientes pasos:\n\n1. Evaluación médica: antes de la cirugía, se realizará una evaluación médica completa para determinar el estado de los implantes y la mejor estrategia de remoción.\n\n2. Incisión y remoción de implantes: durante la cirugía, se realizará una incisión para acceder a los implantes y se removerán de manera segura.\n\n3. Remodelación o levantamiento mamario (opcional): en algunos casos, se puede realizar una remodelación o levantamiento mamario adicional para mejorar la apariencia de los senos después de la explantación.\n\n4. Cierre de incisiones: una vez completada la remoción de los implantes y cualquier procedimiento adicional, se cerrarán las incisiones con suturas.\n\nLa explantación mamaria puede realizarse por diversas razones, incluyendo la insatisfacción estética, complicaciones relacionadas con los implantes o motivos de salud personal. Es importante discutir tus expectativas y preocupaciones con un cirujano plástico especializado en explantación mamaria para determinar el enfoque más adecuado para ti.",
      },
      {
        id: 2,
        titulo: "Levantamiento de senos o pexia mamaria",
        imagen: imgImplantacion,
        tituloSmall: "Cirugía",
        tituloDefinicion: "Definición",
        descripcionDefinicion:
          "El levantamiento de senos, también conocido como pexia mamaria, es un procedimiento quirúrgico utilizado para corregir la flacidez y caída de los senos. Este procedimiento busca devolver la firmeza y juventud a los senos, mejorando su forma y posición.",
        tituloCirugia: "Proceso de Levantamiento de Senos",
        descripcionCirugia:
          "El proceso de levantamiento de senos generalmente involucra los siguientes pasos:\n\n1. Evaluación médica: antes de la cirugía, se realizará una evaluación médica completa para determinar el grado de flacidez y la mejor técnica de levantamiento para tu caso.\n\n2. Incisiones y remodelación: durante la cirugía, se realizarán incisiones estratégicas para remover el exceso de piel y remodelar los tejidos mamarios, elevando los senos a una posición más juvenil.\n\n3. Reposicionamiento de la areola y el pezón: en algunos casos, se puede requerir el reposicionamiento de la areola y el pezón para lograr un resultado estético armonioso.\n\n4. Cierre de incisiones: una vez completada la remodelación y el reposicionamiento, se cerrarán las incisiones con suturas.\n\nEl levantamiento de senos es una opción popular para mujeres que experimentan flacidez y caída de los senos debido a factores como el envejecimiento, la pérdida de peso o el embarazo y la lactancia. Consulta con un cirujano plástico especializado para obtener más información sobre el procedimiento y determinar si es adecuado para ti.",
      },
    ],
  },
];

export default dataServicesA;
