import img1 from "./ServicesImgs/Cirugia-transformed.jpeg";
import img2 from "./ServicesImgs/Viejitop-transformed.jpeg";
import img3 from "./ServicesImgs/Rodilla.jpg";
import img4 from "./ServicesImgs/Cadera.jpg";
import img5 from "./ServicesImgs/Bariatrica.jpg";
import img6 from "./ServicesImgs/mamo-transformed.jpeg";
import img7 from "./ServicesImgs/Lipo.jpg";
import img8 from "./ServicesImgs/explantacion-mamaria-levantamiento.jpg";
import img9 from "./ServicesImgs/Abdominoplastia.jpg";
import img10 from "./ServicesImgs/rino.jpg";
import img11 from "./ServicesImgs/columna-transformed.jpeg";
const dataQuirurgicos = [
  {
    id: 1,
    imagen: img1,
    titulo: "Cirugía Refractiva",
    descripcion:
      "Transforma tu visión y disfruta de una vida sin límites. Nuestra especialidad en corrección de errores refractivos, como miopía, hipermetropía, astigmatismo y presbicia, te brinda la oportunidad de tener una visión clara y nítida.",
    enlace: "/Cirugía-Refractiva",
  },
  {
    id: 2,
    imagen: img2,
    titulo: "Cirugía Catarata",
    descripcion:
      "Obtén una visión renovada y mejora tu calidad de vida con nuestra cirugía de catarata. Nuestro equipo especializado utiliza técnicas avanzadas para brindarte una visión clara y nítida. ¡Recupera tu visión y vive plenamente!",
    enlace: "/Cirugía-Catarata",
  },
  {
    id: 3,
    imagen: img3,
    titulo: "Reemplazo de Rodilla",
    descripcion:
      "Intervención quirúrgica altamente especializada que reemplaza la articulación desgastada, aliviando el dolor y devolviendo la función completa de la rodilla.",
    enlace: "/Reemplazo-Rodilla",
  },
  {
    id: 4,
    imagen: img4,
    titulo: "Reemplazo de Cadera",
    descripcion:
      "Recupera tu movilidad y calidad de vida. Si sufres de dolor y limitaciones en la cadera debido a desgaste, lesiones o condiciones degenerativas, el reemplazo de cadera puede ser la solución que necesitas.",
    enlace: "/Reemplazo-Cadera",
  },
  {
    id: 5,
    imagen: img5,
    titulo: "Cirugía Bariátrica",
    descripcion:
      "La cirugía bariátrica es un procedimiento quirúrgico utilizado para tratar la obesidad y promover la pérdida de peso. Consiste en realizar modificaciones en el sistema digestivo para ayudar a reducir el peso corporal.",
    enlace: "/Cirugía-Bariátrica",
  },
  {
    id: 6,
    imagen: img6,
    titulo: "Mamoplastia de aumento o reducción",
    descripcion:
      " También conocida como cirugía de aumento, reducción o levantamiento de senos, la mamoplastia ofrece opciones personalizadas para abordar diferentes preocupaciones estéticas y de salud relacionadas con los senos.",
    enlace: "/Mamoplastia-aumento-reducción",
  },
  {
    id: 7,
    imagen: img7,
    titulo: "Liposucción",
    descripcion:
      "También conocida como lipoescultura, esta técnica se realiza mediante la aspiración de grasa a través de cánulas delgadas, con el objetivo de remodelar y mejorar la apariencia corporal.",
    enlace: "/Liposucción",
  },
  {
    id: 8,
    imagen: img8,
    titulo: "Cirugía plástica y estética de senos",
    descripcion:
      "La explantación mamaria y el levantamiento de senos, también conocido como pexia mamaria, son procedimientos quirúrgicos que se realizan en el área de la cirugía plástica y estética.",
    enlace: "/Cirugía-plastica-senos",
  },
  {
    id: 9,
    imagen: img9,
    titulo: "Abdominoplastia",
    descripcion:
      "La abdominoplastia es un procedimiento quirúrgico diseñado para mejorar la apariencia del abdomen al eliminar el exceso de piel y grasa abdominal, así como restaurar los músculos debilitados o separados en esa área.",
    enlace: "/Abdominoplastia",
  },
  {
    id: 10,
    imagen: img10,
    titulo: "Rinoplastia",
    descripcion:
      "También conocida como cirugía de nariz, la rinoplastia puede corregir defectos congénitos, problemas respiratorios, lesiones traumáticas o simplemente modificar la apariencia estética de la nariz.",
    enlace: "/Rinoplastia",
  },
  {
    id: 11,
    imagen: img11,
    titulo: "Cirugía de Columna Mínimamente invasiva",
    descripcion:
      "La cirugía de columna mínimamente invasiva es un enfoque quirúrgico innovador utilizado para tratar diversas afecciones de la columna vertebral, como hernias de disco, estenosis espinal, escoliosis y fracturas vertebrales.",
    enlace: "/Columna-Mínimamente-invasiva",
  },
];

export default dataQuirurgicos;
