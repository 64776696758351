import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { NavLink as LinkS } from "react-router-dom";
import { Link } from "react-scroll";
import Img from "../../Images/LogoAzul-svg.svg";
import Img3 from "../../Images/LogoBlanco.svg";

export const MobileIconContainer = styled.div`
  display: flex;
  align-items: center;

  @media screen and (min-width: 769px) {
    display: none;
  }
`;
export const MobileIcon = styled.div`
  font-size: 1.8rem;
  cursor: pointer;
  color: ${({ color }) => (color ? "#000" : "#fff")};
`;

export const OtherComponent = styled.div`
  margin-left: 10px;
`;

export const Nav = styled.nav`
  background-color: ${({ color }) => (color ? "#fff" : "#0c2332")};
  overflow-x: hidden;
  width: 100vw;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  z-index: 10;
  overflow: hidden;
  transition: background-color 1s ease-in-out;

  @media screen and (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    overflow-x: hidden;
    body {
      width: 100%;
      overflow-x: hidden;
    }
  }
`;
export const NavDivider = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #ccc;
  z-index: 1;
  margin-left: 20%;
  margin-right: 20%;
`;

export const NavbarContainer = styled.div`
  display: flex;
  z-index: 1;
  width: 100%;
  justify-content: space-between;
  padding: 0px 24px;
  max-width: 1300px;
`;

export const NavLogo = styled.div`
  width: 20%;
  height: auto;
  cursor: pointer;
  align-items: center;
  text-align: center;
  @media screen and (max-width: 768px) {
    width: 260px;
    height: 80px;
    align-items: center;
    text-align: center;
  }
`;

export const InsideLogo = styled.div`
  background-image: url(${Img3});
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 10;

  transition: background-image ease-in-out;
  ${({ scroll }) =>
    scroll &&
    `
    background-image: url(${Img});
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    `}
  @media screen and (max-width: 1100px) {
    background-size: contain;
  }

  @media screen and (max-width: 768px) {
    z-index: 3;
    background-size: cover;
  }
`;


export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const DivMenu = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  z-index: 1;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
export const NavItem = styled.li`
  height: 80px;
`;
export const NavLinksR = styled(Link)`
  color: ${({ color }) => (color ? "#000" : "#fff")};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1.5rem;
  height: 100%;
  cursor: pointer;

  &.active {
    border-bottom: 0px solid #009dff;
  }
`;
export const NavLinks = styled(LinkS)`
  color: ${({ color }) => (color ? "#000" : "#fff")};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1.5rem;
  height: 100%;
  cursor: pointer;

  &.active {
    border-bottom: 0px solid #009dff;
  }
`;
export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtnLink = styled(LinkR)`
  border-radius: 5px;
  background-color: #009dff;
  white-space: nowrap;
  padding: 10px 15px;
  color: #0c2332;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  border: 2px solid #009dff;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #009dff;
  }
`;
