import React from 'react';
import styled from 'styled-components';
const ImageWrapper = styled.div`
  position: fixed;
  bottom: 10%;
  right: 10%;
  transition: transform 0.2s ease-in-out;
  z-index:999;
  img {
    width: 50px;
    height: 50px;
  }

  &:hover {
    transform: scale(1.2);
  }
`;

const WhatsAppButton = () => {
    const phoneNumber = '573202718442'; // Agrega tu número de teléfono aquí
    const whatsappLink = `https://wa.me/${phoneNumber}/?text=Hola%20estoy%20interesado,%20me%20podrias%20brindar%20mas%20informacion?`;

    return (
        <ImageWrapper >
            <a href={whatsappLink} target='noopener' aria-label='Whatsapp'>
                <img src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" alt="WhatsApp" />
            </a>
        </ImageWrapper>

    );
};

export default WhatsAppButton;
