import React, { useState, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import WhatsAppButton from "./components/WhatsButton";
//Seccion Unica
import dataServices from "./pages/InfoServices/dataInfoServices";

//Seccion Varias cirugias
import dataServicesA from "./pages/InfoServices/dataInfoServicesA";

//Translation
import { useTranslation } from "react-i18next";

//Load
import { Dna } from "react-loader-spinner";

function App() {
  const Home = React.lazy(() => import("./pages/Home"));
  const Contact = React.lazy(() => import("./pages/Contact"));
  const Services = React.lazy(() => import("./pages/Services"));
  const Consult = React.lazy(() => import("./pages/Consult"));
  const AboutUs = React.lazy(() => import("./pages/AboutUs"));
  const InfoServicesSimple = React.lazy(() =>
    import("./pages/InfoServices/InfoServicesSimple")
  );
  const InfoServicesAccordion = React.lazy(() =>
    import("./pages/InfoServices/infoServicesAccordion")
  );
  const [isOpen, setIsOpen] = useState(false);
  const pageRef = React.useRef(null);
  const [t] = useTranslation("global");

  const toggle = () => {
    setIsOpen(!isOpen);

    if (!isOpen) {
      pageRef.current.scrollTop = 0;
    }
  };

  const translatedDataServices = dataServices.map((service) => {
    const translation = t("Services.items").find(
      (item) => item.id === service.id
    );
    return {
      ...service,
      Hero: {
        ...service.Hero,
        titulo: t(translation.Hero.titulo),
        descripcion: t(translation.Hero.descripcion),
      },
      Body: {
        ...service.Body,
        tituloSmall: t(translation.Body.tituloSmall),
        tituloDefinicion: t(translation.Body.tituloDefinicion),
        descripcionDefinicion: t(translation.Body.descripcionDefinicion),
        tituloCirugia: t(translation.Body.tituloCirugia),
        descripcionCirugia: t(translation.Body.descripcionCirugia),
      },
    };
  });

  const translatedDataServicesA = dataServicesA.map((service) => {
    const translation = t("Services.itemsA").find(
      (item) => item.id === service.id
    );
    const translatedBodys = service.Bodys.map((body) => {
      const bodyTranslation = translation.Bodys.find((b) => b.id === body.id);
      return {
        ...body,
        titulo: t(bodyTranslation.titulo),
        tituloSmall: t(bodyTranslation.tituloSmall),
        tituloDefinicion: t(bodyTranslation.tituloDefinicion),
        descripcionDefinicion: t(bodyTranslation.descripcionDefinicion),
        tituloCirugia: t(bodyTranslation.tituloCirugia),
        descripcionCirugia: t(bodyTranslation.descripcionCirugia),
      };
    });
    return {
      ...service,
      Hero: {
        ...service.Hero,
        titulo: t(translation.Hero.titulo),
        descripcion: t(translation.Hero.descripcion),
      },
      Bodys: translatedBodys,
    };
  });

  const servicesElements = translatedDataServices.map((service, index) => (
    <Route
      key={index}
      path={service.path}
      element={<InfoServicesSimple data={service} />}
    />
  ));

  const servicesElementsA = translatedDataServicesA.map((service, index) => (
    <Route
      key={index}
      path={service.path}
      element={<InfoServicesAccordion data={service} />}
    />
  ));

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simula un retraso de 1.5 segundos antes de cambiar el estado a no cargando.
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 1500);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div
      ref={pageRef}
      className={`${
        isOpen ? "overflow-hidden h-screen" : ""
      } transition-all duration-300 ease-in-out`}
    >
      <Router>
        <div>
          <Sidebar isOpen={isOpen} toggle={toggle} />
          <Navbar toggle={toggle} />
          <Suspense
            fallback={
              <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 bg-gray-300 z-50 w-full h-full">
                <div className="bg-white rounded-lg shadow-lg p-4">
                  <Dna
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="dna-loading"
                    wrapperStyle={{}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/contactUs" element={<Contact />} />
              <Route path="/services" element={<Services />} />
              <Route path="/consult" element={<Consult />} />
              <Route path="/aboutUs" element={<AboutUs />} />
              {servicesElements}
              {servicesElementsA}
            </Routes>
          </Suspense>

          <Footer />
          <WhatsAppButton />
        </div>
      </Router>
    </div>
  );
}

export default App;
