import implantesDentales from "./ServicesImgs/diseñosmile.jpeg";
import diseñoSonrrisa from "./ServicesImgs/implante.jpeg";
import OrtodonciaInv from "./ServicesImgs/OrtodonciaInvisible.jpeg"
const dataNoQuirurgicos = [
  {
    id: 1,
    imagen: implantesDentales,
    titulo: "Implantes Dentales",
    descripcion: "Producto sanitario diseñado para sustituir la raíz que falta y mantener el diente artificial en su sitio. Habitualmente tiene forma roscada y está fabricado con materiales biocompatibles que no producen reacción de rechazo y permiten su unión al hueso.",
    enlace: "/Implantes-Dentales",
  },
  {
    id: 2,
    imagen: diseñoSonrrisa,
    titulo: "Diseño de Sonrisa",
    descripcion: "Procedimiento mediante el cual se modifica el aspecto de los dientes para conseguir una apariencia armónica. Al aplicar esta técnica se corrige la alineación de los dientes, se cambia el tamaño, color y forma de los dientes según lo necesite el paciente.",
    enlace: "/Diseño-Sonrisa",
  },
  {
    id: 3,
    imagen: OrtodonciaInv,
    titulo: "Ortodoncia Invisible",
    descripcion: "La Ortodoncia Invisible es un procedimiento mediante el cual se modifica la apariencia de los dientes para lograr una armonía estética. Al aplicar esta técnica, se corrige la alineación de los dientes y se ajusta el tamaño, color y forma de los dientes.",
    enlace: "/Ortodoncia-Invisible"
  }
  
];

export default dataNoQuirurgicos;
